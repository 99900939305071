// @flow

import * as React from 'react';

// The syntax does not allow for importing both the whole thing and types, so we disable no-duplicates
// eslint-disable-next-line import/no-duplicates
import * as most from 'most';
// eslint-disable-next-line import/no-duplicates
import { type Stream } from 'most';

import SubscribeStream from './SubscribeStream';

type Props<V> = {|
  value: V,
  delay: number,
  children: V => React.Node
|};
type State<V> = {|
  observedValue: Stream<V>
|};

export default class Delayed<V> extends React.Component<Props<V>, State<V>> {
  constructor(props: Props<V>) {
    super(props);
    this.state = {
      observedValue: most.of(props.value)
    };
  }
  UNSAFE_componentWillReceiveProps(
    nextProps: Props<V>,
    currentProps: Props<V>
  ) {
    if (nextProps.value !== currentProps.value) {
      this.setState({
        observedValue: most.of(nextProps.value).delay(nextProps.delay)
      });
    }
  }
  render(): React.Node {
    return (
      <SubscribeStream stream={this.state.observedValue}>
        {value => this.props.children(value)}
      </SubscribeStream>
    );
  }
}
