// @flow

import * as React from 'react';
import cx from 'classnames';

import { inputTarget } from 'common/eventTargets';

import styles from './TextInput.scss';

export default function TextInput(props: {
  type?: 'text' | 'search',
  value?: string,
  onValue: string => void,
  className?: string,
  ...
}): React.Node {
  const { value, onValue, className, ...otherProps } = props;

  const handleChange = (e: Event) => {
    onValue(inputTarget(e).value);
  };
  return (
    <input
      {...otherProps}
      type={props.type || 'text'}
      value={value || ''}
      onChange={handleChange}
      className={cx(className, styles.input)}
    />
  );
}
