// @flow

const PATH_SEGMENT_REGEX = /([^\/]+)/g;
const routeTranslations = __obj('routes');

// Replicate behaviour of RoutingFilter::Translation
export default function translate(path: string): string {
  return path.replace(PATH_SEGMENT_REGEX, (segment: string) => {
    return routeTranslations[segment] || segment;
  });
}
