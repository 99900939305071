// @flow

import currentSite from 'site/currentSite';

export default function prependLocale(path: string): string {
  const locale = __LOCALE__;
  const siteLocale = currentSite().locale;
  const siteCountryInPath = currentSite().countryInPath;

  if (locale === siteLocale || siteCountryInPath !== null) {
    return path;
  } else {
    return ['/', locale, path].join('');
  }
}
