// @flow

import * as React from 'react';
import classnames from 'classnames';

import Button from 'common/components/Button';

import styles from './Tabs.scss';

type SharedTabProps = {|
  active?: boolean,
  children: React.Node
|};

export type TabButtonProps = {
  ...SharedTabProps,
  ...
};

export type TabLinkProps = {|
  ...SharedTabProps,
  href?: string
|};

export function TabLink(props: TabLinkProps): React.Node {
  const { active, children, href } = props;
  return (
    <a
      href={href}
      className={classnames({
        [styles.activeTab]: !!active,
        [styles.inactiveTab]: !active
      })}
    >
      {children}
    </a>
  );
}

export function TabButton(props: TabButtonProps): React.Node {
  const { active, ...otherProps } = props;

  return (
    <Button
      {...otherProps}
      className={classnames({
        [styles.activeTab]: !!active,
        [styles.inactiveTab]: !active
      })}
    />
  );
}

type TabGroupProps = {|
  children: React.Node,
  className?: string
|};

export function TabGroup(props: TabGroupProps): React.Node {
  return (
    <div className={classnames(styles.tabGroup, props.className)}>
      {props.children}
    </div>
  );
}
