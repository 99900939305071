// @flow

import { createStore, applyMiddleware } from 'redux';
import type { Store, Reducer, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import loggerMiddleware from 'common/middleware/logger';

/**
 * Configures a redux store. For development, adds action logging, dev tools and a hook to bolt on
 * hot reload functionality.
 */
export default function configureStore<
  S: Object,
  A: { type: any, ... }
>(options: {|
  rootReducer: Reducer<S, A>,
  middlewares?: Array<Middleware<S, A>>,
  hookToStore?: (store: Store<S, A>) => void
  // There are variety of different Dispatch types, so we can't provide the third
  // type argument for Store here with ease.
|}): Store<S, A, any> {
  const middlewares = options.middlewares || [];
  if (__DEV__) {
    middlewares.push(loggerMiddleware);
  }

  const storeEnhancer = composeWithDevTools(applyMiddleware(...middlewares));
  const store = createStore(options.rootReducer, storeEnhancer);

  if (options.hookToStore) {
    options.hookToStore(store);
  }

  return store;
}
