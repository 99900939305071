// @flow

import * as React from 'react';
import classnames from 'classnames';

import Button, { type Props as ButtonProps } from './Button';
import FAIcon from './FAIcon';

import styles from './IconButton.scss';

type IconButtonProps = {
  icon: string,
  children: React.Node,
  iconPosition?: 'left' | 'right',
  iconSpin?: boolean,
  iconPulse?: boolean,
  ...
} & ButtonProps;

export default function IconButton(props: IconButtonProps): React.Node {
  const {
    icon,
    children,
    iconPosition = 'right',
    iconSpin = false,
    iconPulse = false,
    ...otherProps
  } = props;

  const leftOrRightIcon = (
    <FAIcon
      name={icon}
      className={classnames({
        [styles.icon]: true,
        [styles.left]: iconPosition === 'left',
        [styles.right]: iconPosition === 'right',
        [styles.spin]: iconSpin,
        [styles.pulse]: iconPulse
      })}
      spin={iconSpin}
      pulse={iconPulse}
    />
  );

  return (
    <Button {...otherProps}>
      {iconPosition === 'left' ? leftOrRightIcon : null}
      {children}
      {iconPosition === 'right' ? leftOrRightIcon : null}
    </Button>
  );
}
