// @flow

import * as React from 'react';
import ReactModal2 from 'react-modal2';
import { findDOMNode, createPortal } from 'react-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import classnames from 'classnames';

import { withElement } from 'common/dom';

import BodyScrollPreventer from './BodyScrollPreventer';
import styles from './Modal.scss';

export const modalWindowClass = styles.content;

type RefValue = null | React.Component<any, any> | HTMLElement;

// Allow ReactModal2 to hide the "application" for a11y reasons when modals are opened.
// Modals are always opened outside the element targeted by this following function.
// See: https://github.com/cloudflare/react-modal2#accessibility
ReactModal2.getApplicationElement = () =>
  document.getElementById('a11y-application-container');

const Transition = (props: {| children: React.Node |}) => {
  return (
    <ReactCSSTransitionGroup
      transitionName={{
        appear: styles.fadeAppear,
        appearActive: styles.fadeAppearActive,
        enter: styles.fadeEnter,
        enterActive: styles.fadeEnterActive,
        leave: 'leave'
      }}
      transitionAppear
      transitionEnter
      transitionLeave={false}
      transitionAppearTimeout={250}
      transitionEnterTimeout={250}
    >
      {props.children}
    </ReactCSSTransitionGroup>
  );
};

type ModalProps = {|
  onClose: () => void,
  windowClassName?: string,
  children: React.Node
|};

export default function Modal(props: ModalProps): React.Node {
  return (
    <ModalBackground onClose={props.onClose}>
      <ModalWindow onClose={props.onClose} className={props.windowClassName}>
        {props.children}
      </ModalWindow>
    </ModalBackground>
  );
}

type ModalBackgroundProps = {|
  onClose: () => void,
  children: React.Node
|};

export class ModalBackground extends React.Component<
  ModalBackgroundProps,
  { renderModal: boolean, ... }
> {
  modalScrollContainerElement: ?HTMLElement;
  node: ?HTMLElement;

  state: { renderModal: boolean, ... } = {
    renderModal: false
  };

  componentDidMount() {
    // Work around Mobile Safari scrolling the body to the top of the page when modal mounts.
    // By delaying the modal rendering by two ticks, we allow Safari to recover the viewport
    // height after a form field has been blurred, and to not screw up the scroll position
    // when a backdrop with `position: fixed` style is added to the DOM.
    //
    // Two ticks are needed, as hiding keyboard seems to take one tick and hiding the bottom
    // takes another tick. I'm not very confident on this, though...
    //
    // See https://github.com/venuu/venuu/pull/2293#issuecomment-270116195
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        this.setState({ renderModal: true });
      });
    });
  }

  componentWillUnmount() {
    if (this.node && document.body) {
      document.body.removeChild(this.node);
      this.node = null;
    }
  }

  render(): React$Portal {
    let node;
    if (!this.node) {
      this.node = document.createElement('div');
      node = this.node;
      if (document.body) {
        document.body.appendChild(this.node);
      }
    } else {
      node = this.node;
    }
    return createPortal(
      <Transition>
        {this.state.renderModal ? this._renderModal() : null}
      </Transition>,
      node
    );
  }

  _renderModal(): React.Node {
    const props = this.props;

    return (
      <BodyScrollPreventer
        getScrollLimitElement={() => this.modalScrollContainerElement}
      >
        <ReactModal2
          onClose={props.onClose}
          closeOnEsc
          backdropClassName={styles.fullPageBackground}
          modalClassName={styles.modalScrollContainer}
          ref={this._storeModalScrollContainerElement}
        >
          <div>
            <div
              className={styles.fakeFullHeightBackground}
              onClick={props.onClose}
            />
            {props.children}
          </div>
        </ReactModal2>
      </BodyScrollPreventer>
    );
  }

  _storeModalScrollContainerElement: (reactModalElement: RefValue) => void = (
    reactModalElement: RefValue
  ) => {
    // The ref node received from react-modal2 is the backdrop element. We need to get access
    // to the only child inside the modal, which will be the one to contain the scroll
    this.modalScrollContainerElement = withElement(
      findDOMNode(reactModalElement),
      (element: Element) => element.children[0],
      () => null
    );
  };
}

type ModalWindowProps = {|
  onClose: () => void,
  className?: string,
  children: React.Node
|};

export function ModalWindow(props: ModalWindowProps): React.Node {
  return (
    <div className={classnames(styles.content, props.className)}>
      {props.children}
      <CloseButton onClick={props.onClose} />
    </div>
  );
}

export function CloseButton(props: {| onClick: () => void |}): React.Node {
  return (
    <button type="button" className={styles.close} onClick={props.onClick}>
      ×
    </button>
  );
}
