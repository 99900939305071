/* This is copypasta, disable offending lint rules */
/* eslint-disable no-var, one-var, no-eq-null, eqeqeq, prefer-arrow-callback, consistent-return, no-param-reassign */

var COMPLETE = 'complete',
  CANCELED = 'canceled';

function setElementScroll(element, x, y) {
  if (element === window) {
    element.scrollTo(x, y);
  } else {
    element.scrollLeft = x;
    element.scrollTop = y;
  }
}

function getTargetScrollLocation(target, parent, align) {
  var targetPosition = target.getBoundingClientRect(),
    parentPosition,
    x,
    y,
    differenceX,
    differenceY,
    leftAlign = align && align.left != null ? align.left : 0.5,
    topAlign = align && align.top != null ? align.top : 0.5,
    leftScalar = leftAlign,
    topScalar = topAlign;

  if (parent === window) {
    x =
      targetPosition.left +
      window.scrollX -
      window.innerWidth * leftScalar +
      Math.min(targetPosition.width, window.innerWidth) * leftScalar;
    y =
      targetPosition.top +
      window.scrollY -
      window.innerHeight * topScalar +
      Math.min(targetPosition.height, window.innerHeight) * topScalar;
    x = Math.max(
      Math.min(x, document.body.scrollWidth - window.innerWidth * leftScalar),
      0
    );
    y = Math.max(
      Math.min(y, document.body.scrollHeight - window.innerHeight * topScalar),
      0
    );
    differenceX = x - window.scrollX;
    differenceY = y - window.scrollY;
  } else {
    parentPosition = parent.getBoundingClientRect();
    var offsetTop =
      targetPosition.top - (parentPosition.top - parent.scrollTop);
    var offsetLeft =
      targetPosition.left - (parentPosition.left - parent.scrollLeft);
    x =
      offsetLeft +
      targetPosition.width * leftScalar -
      parent.clientWidth * leftScalar;
    y =
      offsetTop +
      targetPosition.height * topScalar -
      parent.clientHeight * topScalar;
    x = Math.max(Math.min(x, parent.scrollWidth - parent.clientWidth), 0);
    y = Math.max(Math.min(y, parent.scrollHeight - parent.clientHeight), 0);
    differenceX = x - parent.scrollLeft;
    differenceY = y - parent.scrollTop;
  }

  return {
    x: x,
    y: y,
    differenceX: differenceX,
    differenceY: differenceY
  };
}

function animate(parent) {
  window.requestAnimationFrame(function () {
    var scrollSettings = parent._scrollSettings;
    if (!scrollSettings) {
      return;
    }

    var location = getTargetScrollLocation(
        scrollSettings.target,
        parent,
        scrollSettings.align
      ),
      time = Date.now() - scrollSettings.startTime,
      timeValue = Math.min((1 / scrollSettings.time) * time, 1);

    if (
      time > scrollSettings.time + 20 ||
      (Math.abs(location.differenceY) <= 1 &&
        Math.abs(location.differenceX) <= 1)
    ) {
      setElementScroll(parent, location.x, location.y);
      parent._scrollSettings = null;
      return scrollSettings.end(COMPLETE);
    }

    var valueX = timeValue,
      valueY = timeValue;

    setElementScroll(
      parent,
      location.x - location.differenceX * Math.pow(1 - valueX, valueX / 2),
      location.y - location.differenceY * Math.pow(1 - valueY, valueY / 2)
    );

    animate(parent);
  });
}

function transitionScrollTo(target, parent, settings, callback) {
  var idle = !parent._scrollSettings,
    lastSettings = parent._scrollSettings,
    now = Date.now();

  if (lastSettings) {
    lastSettings.end(CANCELED);
  }

  function end(endType) {
    parent._scrollSettings = null;
    callback(endType);
    parent.removeEventListener('touchstart', end);
  }

  parent._scrollSettings = {
    startTime: lastSettings ? lastSettings.startTime : Date.now(),
    target: target,
    time: settings.time + (lastSettings ? now - lastSettings.startTime : 0),
    ease: settings.ease,
    align: settings.align,
    end: end
  };
  parent.addEventListener('touchstart', end.bind(null, CANCELED));

  if (idle) {
    animate(parent);
  }
}

export default function scrollIntoView(target, settings, callback) {
  if (!target) {
    return;
  }

  if (typeof settings === 'function') {
    callback = settings;
    settings = null;
  }

  if (!settings) {
    settings = {};
  }

  settings.time = isNaN(settings.time) ? 1000 : settings.time;
  settings.ease =
    settings.ease ||
    function (v) {
      return v;
    };

  var parent = target.parentElement,
    parents = 0;

  function done(endType) {
    parents--;
    if (!parents) {
      callback && callback(endType);
    }
  }

  while (parent) {
    if (
      settings.validTarget
        ? settings.validTarget(parent, parents)
        : (true && parent === window) ||
          ((parent.scrollHeight !== parent.clientHeight ||
            parent.scrollWidth !== parent.clientWidth) &&
            getComputedStyle(parent).overflow !== 'hidden')
    ) {
      parents++;
      transitionScrollTo(target, parent, settings, done);
    }

    parent = parent.parentElement;

    if (!parent) {
      return;
    }

    if (parent.tagName === 'BODY') {
      parent = window;
    }
  }
}
