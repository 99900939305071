// @flow

import { findDOMNode } from 'react-dom';
import * as React from 'react';

export function getDOMNode(
  componentOrElement: Element | React.Component<any, any> | null
): Element | Text {
  const node = findDOMNode(componentOrElement);
  if (node === null) {
    throw new Error(
      `Could not find DOM node by selector '${JSON.stringify(
        componentOrElement
      )}'`
    );
  }
  return node;
}

export function withDocumentBody<V>(f: (body: Element) => V): V {
  if (document.body) {
    return f(document.body);
  }
  throw new Error('document.body not present');
}

export function withDocumentHead<V>(f: (head: Element) => V): V {
  if (document.head) {
    return f(document.head);
  }
  throw new Error('document.head not present');
}

export function withElement<V, Other>(
  maybeElement: Element | Other,
  whenElement: Element => V,
  whenNotElement?: Other => V
): V | null {
  if (maybeElement instanceof Element) {
    return whenElement(maybeElement);
  } else if (whenNotElement) {
    return whenNotElement(maybeElement);
  } else {
    const received =
      JSON.stringify(maybeElement) ||
      'something for which JSON.stringify fails';
    throw new Error(
      `Expected an instance of Element, instead received ${received}`
    );
  }
}
