// @flow

import * as React from 'react';

type BeforeUnloadWarning = string | void;
type BeforeUnloadEvent = Event & { returnValue: BeforeUnloadWarning, ... };
type BeforeUnloadHandler = BeforeUnloadEvent => BeforeUnloadWarning;

export default class UnsavedChangesWarning extends React.Component<{
  warningMessage: string,
  hasUnsavedChanges: boolean,
  ...
}> {
  static defaultProps: {| warningMessage: string |} = {
    // FIXME: differentiate string & object translations
    warningMessage: __('unsaved_changes_warning')
  };

  componentDidMount() {
    // Work around https://github.com/cypress-io/cypress/issues/2118
    if (window.Cypress) return;
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
  }

  componentWillUnmount() {
    // Work around https://github.com/cypress-io/cypress/issues/2118
    if (window.Cypress) return;
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
  }

  render(): null {
    return null;
  }

  beforeUnloadHandler: BeforeUnloadHandler = event => {
    const { warningMessage, hasUnsavedChanges } = this.props;

    if (hasUnsavedChanges) {
      event.returnValue = warningMessage;
      return warningMessage;
    }

    return undefined;
  };
}
