// @flow

import { ajax } from 'jquery';

import type { JSONValue } from 'common/types';
import { type Result, failure, success } from './types/Result';

export type RequestOptions = {|
  url: string,
  method?: 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE' | 'HEAD',
  data: JSONValue
|};

export type ErrorState =
  | 'NO_INTERNET_CONNECTION'
  | 'UNAUTHORIZED'
  | 'UNEXPECTED_ERROR';

export type ApiRequestFailure = {|
  requestOptions: RequestOptions,
  responseJson: JSONValue,
  errorState: ErrorState
|};
export type ApiRequestResult = Result<JSONValue, ApiRequestFailure>;

export const put = (url: string, data: JSONValue): Promise<ApiRequestResult> =>
  apiRequest({
    url,
    data,
    method: 'PUT'
  });

export default function apiRequest(
  requestOptions: RequestOptions
): Promise<ApiRequestResult> {
  return new Promise(resolve => {
    ajax({
      url: requestOptions.url,
      method: requestOptions.method,
      data: JSON.stringify(requestOptions.data),
      dataType: 'json',
      headers: { Accept: 'application/json' },
      contentType: 'application/json',
      processData: false,
      error: jqueryAjaxResponse => {
        resolve(
          failure({
            requestOptions: requestOptions,
            responseJson: jqueryAjaxResponse.responseJSON,
            errorState: jqueryAjaxResponseToErrorState(jqueryAjaxResponse)
          })
        );
      }
    }).then(value => {
      resolve(success(value));
    });
  });
}

function jqueryAjaxResponseToErrorState(jqueryAjaxResponse: any): ErrorState {
  if (jqueryAjaxResponse.readyState === 0) {
    return 'NO_INTERNET_CONNECTION';
  }

  if (jqueryAjaxResponse.status === 403) {
    return 'UNAUTHORIZED';
  }

  return 'UNEXPECTED_ERROR';
}
