// @flow

import * as React from 'react';
import styles from './InputErrorWrapperNew.scss';

import FontAwesomeIcon, {
  exclamationTriangle
} from 'common/components/FontAwesomeIcon';

type InputErrorWrapperProps = {|
  message: string,
  children: React.Node,
  errorId: string,
  customIconPosition?: string
|};
export default function InputErrorWrapperNew({
  message,
  children,
  errorId,
  customIconPosition
}: InputErrorWrapperProps): React.Node {
  return (
    <div className={styles.errorContainer} data-error={errorId}>
      {children}
      {message && (
        <>
          <div
            className={styles.invalidFieldIndicator}
            style={{ right: customIconPosition }}
          >
            <FontAwesomeIcon icon={exclamationTriangle} />
          </div>
          <div className={styles.errorMessage}>
            <span>{message}</span>
          </div>
        </>
      )}
    </div>
  );
}
