// @flow

import impossible from 'common/types/impossible';

export type Success<SuccessValue> = {|
  type: 'SUCCESS',
  value: SuccessValue
|};
export type Failure<FailureValue> = {|
  type: 'FAILURE',
  value: FailureValue
|};
export type Result<SuccessValue, FailureValue> =
  | Success<SuccessValue>
  | Failure<FailureValue>;

export function foldResult<SuccessValue, FailureValue, V>(
  result: Result<SuccessValue, FailureValue>,
  branches: {|
    success: SuccessValue => V,
    failure: FailureValue => V
  |}
): V {
  switch (result.type) {
    case 'SUCCESS':
      return branches.success(result.value);
    case 'FAILURE':
      return branches.failure(result.value);
    default:
      return impossible(result.type);
  }
}

export function mapSuccess<SuccessIn, SuccessOut, FailureInOut>(
  result: Result<SuccessIn, FailureInOut>,
  onSuccess: SuccessIn => SuccessOut
): Result<SuccessOut, FailureInOut> {
  switch (result.type) {
    case 'SUCCESS':
      return (success(onSuccess(result.value)): Result<
        SuccessOut,
        FailureInOut
      >);
    case 'FAILURE':
      return (failure(result.value): Result<SuccessOut, FailureInOut>);
    default:
      return impossible(result.type);
  }
}

export function tapSuccess<SuccessValue, FailureValue>(
  result: Result<SuccessValue, FailureValue>,
  onSuccess: SuccessValue => mixed
): Result<SuccessValue, FailureValue> {
  if (result.type === 'SUCCESS') {
    onSuccess(result.value);
  }
  return result;
}

export function success<V>(value: V): Success<V> {
  return {
    type: 'SUCCESS',
    value
  };
}

export function failure<V>(value: V): Failure<V> {
  return {
    type: 'FAILURE',
    value
  };
}
