// @flow

const ongoingProcesses: { [string]: number } = {};
window.PLEASE_WAIT = ongoingProcesses;

export default function pleaseWaitFor<A>(
  processName: string,
  run: () => Promise<A>
): Promise<A> {
  // There's no `finally` on our Promise so we do a bit of a tapdance with `then`
  const end = toOnCompleteListeners(start(processName));
  // Our `run` might throw immediately if it's not an async, because any throwing function is,
  // according to flow, compatible with any return value
  return tryPromise(run).then(end.onSuccess, end.onFailure);
}

function start(processName: string): () => void {
  if (!ongoingProcesses[processName]) {
    ongoingProcesses[processName] = 1;
  } else {
    ++ongoingProcesses[processName];
  }

  return function end() {
    --ongoingProcesses[processName];
    if (ongoingProcesses[processName] === 0) {
      delete ongoingProcesses[processName];
    }
  };
}

function toOnCompleteListeners(onSuccessAndFailure: () => void) {
  return {
    onSuccess: <V>(v: V): V => {
      onSuccessAndFailure();
      return v;
    },
    onFailure: (e: Error): Promise<any> => {
      onSuccessAndFailure();
      return Promise.reject(e);
    }
  };
}

function tryPromise<V>(f: void => V): Promise<V> {
  return new Promise((resolve, reject) => {
    try {
      resolve(f());
    } catch (e) {
      reject(e);
    }
  });
}
