// @flow

import * as React from 'react';

import type QuillEditor from 'quill';

type ToolbarProps = {|
  editor: QuillEditor,
  className?: string,
  children: React.Node
|};

class Toolbar extends React.Component<ToolbarProps> {
  static displayName: ?string = 'RichTextEditor_Toolbar';

  shouldComponentUpdate(): boolean {
    // The toolbar state is kept in Quill, which directly mutates DOM to apply
    // new states. That's why we can bail out of re-rendering always.
    return false;
  }

  render(): React.Node {
    const { className, children } = this.props;
    return (
      <div ref={this._addToolbarToNode} className={className}>
        {children}
      </div>
    );
  }

  _addToolbarToNode: (
    node: null | HTMLDivElement | React.ElementRef<string>
  ) => void = node => {
    if (!node) return;
    this.props.editor.addModule('toolbar', { container: node });
  };
}

class ToolbarFormatGroup extends React.Component<{
  children: React.Node,
  ...
}> {
  static displayName: ?string = 'RichTextEditor_ToolbarFormatGroup';

  render(): React.Node {
    return <div className="ql-format-group">{this.props.children}</div>;
  }
}

type ButtonTypes = {|
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  LIST: 'list',
  BULLET: 'bullet'
|};
const buttonTypes: ButtonTypes = {
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  LIST: 'list',
  BULLET: 'bullet'
};

// TODO: Figure out what to do with the "type" values duplication
type ToolbarButtonProps = {|
  type: 'bold' | 'italic' | 'underline' | 'list' | 'bullet'
|};

class ToolbarButton extends React.Component<ToolbarButtonProps> {
  static displayName: ?string = 'RichTextEditor_ToolbarButton';

  static types: ButtonTypes = buttonTypes;

  render(): React.Node {
    const { type } = this.props;
    const className = `ql-format-button ql-${type}`;
    return <span className={className} />;
  }
}

const linkTooltipTemplate = `
<span class="title">Visit URL:&nbsp;</span>
<a href="#" class="url" target="_blank" href="about:blank"></a>
<input class="input" type="text">
<span>&nbsp;&#45;&nbsp;</span>
<a href="javascript:;" class="change">Change</a>
<a href="javascript:;" class="remove">Remove</a>
<a href="javascript:;" class="done">Done</a>
`;

type ToolbarButtonLinkProps = {|
  editor: QuillEditor
|};

class ToolbarButtonLink extends React.Component<ToolbarButtonLinkProps> {
  static displayName: ?string = 'RichTextEditor_ToolbarButtonLink';

  componentDidMount() {
    this.props.editor.addModule('link-tooltip', {
      template: linkTooltipTemplate
    });
  }

  render(): React.Node {
    const { editor: _, ...otherProps } = this.props;
    return <span {...otherProps} className="ql-format-button ql-link" />;
  }
}

type ToolbarSeparatorProps = {||};

class ToolbarSeparator extends React.Component<ToolbarSeparatorProps> {
  static displayName: ?string = 'RichTextEditor_ToolbarSeparator';

  render(): React.Node {
    return <span className="ql-format-separator" />;
  }
}

export {
  Toolbar,
  ToolbarFormatGroup,
  ToolbarButton,
  ToolbarButtonLink,
  ToolbarSeparator
};
