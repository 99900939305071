// @flow
import * as React from 'react';

export default function Render(props: {
  when: boolean,
  children: React.Node,
  ...
}): React.Node {
  if (props.when) {
    return props.children;
  } else {
    return null;
  }
}
