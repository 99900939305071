import * as React from 'react';
import shallowCompare from 'react-addons-shallow-compare';

import spinner from 'images/spinner@2x.gif';

class SpinnerIcon extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render(): React.Node {
    return (
      <img
        src={spinner}
        width="24"
        height="24"
        // Ignore the GIF changing when running Chromatic visual diffing.
        // Docs: https://www.chromatic.com/docs/ignoring-elements
        data-chromatic="ignore"
      />
    );
  }
}

export default SpinnerIcon;
