// @flow

import { stringify as stringifyQuery } from 'qs';
import translate from './translate';
import prependLocale from './prependLocale';
import prependCountryAndLocale from './prependCountryAndLocale';
import type { PathParams, QueryParams } from './types';

type FormatOptions = {|
  translate: boolean,
  prependLocale: boolean
|};

export const EMPTY_PARAM = 'EMPTY_PARAM';

export default function format(
  template: string,
  pathParams: PathParams,
  queryParams: QueryParams,
  options: FormatOptions
): string {
  let result = template;

  if (options.translate) {
    result = translate(result);
  }
  if (options.prependLocale) {
    result = prependLocale(result);
  }
  result = prependCountryAndLocale(result);
  result = interpolatePath(result, pathParams);
  result = appendQuery(result, queryParams);

  return result;
}

function interpolatePath(path: string, pathParams: PathParams): string {
  return Object.keys(pathParams).reduce((result, param) => {
    const value = pathParams[param];

    if (!value) {
      throw new Error(`No value supplied for path parameter :${param}`);
    }

    if (value === EMPTY_PARAM) {
      // Remove the part of path wrapped in parentheses which contains the optional param,
      // turning path like `/foo(/:bar)` to `/foo`
      const paramRegex = RegExp(`\\([^:]*:${param}\\)`, 'gi');
      if (!paramRegex.test(result)) {
        // It would be nice at some point to enforce this on the type-level
        // so we could get rid of this runtime error in favor of type-level error.
        throw new Error(`Path parameter "${param}" is required`);
      }
      return result.replace(paramRegex, '');
    }

    return (
      result
        // Clean up any parentheses left by optional path parameters first
        .replace(RegExp(`\\(([^:]*:${param})\\)`, 'gi'), '$1')
        // Then replace the path parameter with the given value
        .replace(RegExp(`\\:${param}`, 'gi'), value.toString())
    );
  }, path);
}

function appendQuery(path: string, queryParams: QueryParams): string {
  const query = stringifyQuery(queryParams, {
    arrayFormat: 'brackets',
    skipNulls: true
  });

  if (query) {
    return `${path}?${query}`;
  } else {
    return path;
  }
}
