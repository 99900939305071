// @flow

import * as React from 'react';

import FAIcon from 'common/components/FAIcon';

type Props = {
  isOpen: boolean,
  icon?: 'chevron' | 'caret',
  ...
};

export default function AccordionStateIcon(props: Props): React.Node {
  const icon = props.icon || 'chevron';
  const chevronIcon = `${icon}-${props.isOpen ? 'up' : 'down'}`;
  return <FAIcon name={chevronIcon} />;
}
