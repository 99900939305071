// @flow

import * as React from 'react';
import { type Stream, type Subscription } from 'most';

type Props<V> = {|
  stream: Stream<V>,
  children: V => React.Node
|};
type State<V> = {|
  latestValue: V | void
|};

export default class SubscribeStream<V> extends React.Component<
  Props<V>,
  State<V>
> {
  subscription: ?Subscription<V>;
  constructor(props: Props<V>) {
    super(props);
    this.state = {
      latestValue: undefined
    };
  }
  componentDidMount() {
    this.subscription = this.props.stream.subscribe({
      next: latestValue => {
        this.setState({ latestValue });
      },
      error: () => {},
      complete: () => {}
    });
  }
  UNSAFE_componentWillReceiveProps(props: Props<V>) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = props.stream.subscribe({
      next: latestValue => {
        this.setState({ latestValue });
      },
      error: () => {},
      complete: () => {}
    });
  }
  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  render(): React.Node {
    if (typeof this.state.latestValue === 'undefined') {
      return null;
    }
    return this.props.children(this.state.latestValue);
  }
}
