// @flow

export function buttonTarget(e: Event): HTMLButtonElement {
  if (e.target instanceof HTMLButtonElement) {
    return e.target;
  } else {
    throw new Error(
      `Expected Event target to be HTMLButtonElement but found ${typeof e.target}`
    );
  }
}

export function inputTarget(e: Event): HTMLInputElement {
  if (e.target instanceof HTMLInputElement) {
    return e.target;
  } else {
    throw new Error(
      `Expected Event target to be HTMLInputElement but found ${typeof e.target}`
    );
  }
}

export function textareaTarget(e: Event): HTMLTextAreaElement {
  if (e.target instanceof HTMLTextAreaElement) {
    return e.target;
  } else {
    throw new Error(
      `Expected Event target to be HTMLTextAreaElement but found ${typeof e.target}`
    );
  }
}

export function selectTarget(e: Event): HTMLSelectElement {
  if (e.target instanceof HTMLSelectElement) {
    return e.target;
  } else {
    throw new Error(
      `Expected Event target to be HTMLSelectElement but found ${typeof e.target}`
    );
  }
}

export const eventTargetValue = (event: mixed): string => {
  if (
    event &&
    typeof event === 'object' &&
    event.target &&
    typeof event.target === 'object' &&
    typeof event.target.value === 'string'
  ) {
    return event.target.value;
  }
  throw new Error(
    'Expected event object with `event.target.value` set to a string'
  );
};
