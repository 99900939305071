// @flow

import * as React from 'react';
import $ from 'jquery';

import { withDocumentBody } from 'common/dom';

// Disables body element scrolling on touch past the element passed in.
// This is the only way to stop the body element from scrolling on Mobile Safari
function preventOverscroll(el: HTMLElement) {
  // If the element is not scrollable, the body may scroll instead.
  // We need to make sure that there is always room to scroll the element: upwards, if we are at the
  // top, or downwards if we are at the bottom.
  const ensureElementWillBeScrolledIfScrollable = () => {
    const totalScrollableHeight = el.scrollHeight;
    const visibleElementHeight = el.offsetHeight;
    const isElementScrollable = visibleElementHeight < totalScrollableHeight;

    // Changing el.scrollTop only works when the element is scrollable
    if (isElementScrollable) {
      const elementScrollPositionTop = el.scrollTop;
      const elementScrollPositionBottom =
        elementScrollPositionTop + visibleElementHeight;

      const currentlyScrolledToTop = elementScrollPositionTop === 0;
      const currentlyScrolledToBottom =
        elementScrollPositionBottom === totalScrollableHeight;
      // Scroll one pixel down if we are at the top, and one pixel up for bottom
      if (currentlyScrolledToTop) {
        el.scrollTop += 1;
      } else if (currentlyScrolledToBottom) {
        el.scrollTop -= 1;
      }
    }
  };

  const preventScrolling = (evt: TouchEvent) => {
    // Prevent all the scrolling that has bubbled all the way to document.body
    evt.preventDefault();
  };

  const preventScrollPreventionOnElementIfScrollable = (evt: TouchEvent) => {
    const visibleElementHeight = el.offsetHeight;
    const totalScrollableHeight = el.scrollHeight;

    // If the element is long enough, it can handle its own scroll
    const isElementScrollable = visibleElementHeight < totalScrollableHeight;
    if (isElementScrollable) {
      // In this case we stop propagation to prevent the scroll prevention from being called
      evt.stopPropagation();
    }
  };

  el.addEventListener('touchstart', ensureElementWillBeScrolledIfScrollable);
  el.addEventListener(
    'touchmove',
    preventScrollPreventionOnElementIfScrollable
  );
  // Prevent all scrolling that reaches document.body. Note that this will NOT fire if the touch
  // originated behind the Mobile Safari bottom browser area. This is a known tradeoff:
  // https://github.com/venuu/venuu/pull/2293#issuecomment-270137705
  withDocumentBody(body =>
    body.addEventListener('touchmove', preventScrolling)
  );

  return () => {
    el.removeEventListener(
      'touchstart',
      ensureElementWillBeScrolledIfScrollable
    );
    el.removeEventListener(
      'touchmove',
      preventScrollPreventionOnElementIfScrollable
    );
    withDocumentBody(body =>
      body.removeEventListener('touchmove', preventScrolling)
    );
  };
}

export default class BodyScrollPreventer extends React.Component<{
  getScrollLimitElement?: () => ?HTMLElement,
  children: React.Node,
  ...
}> {
  disableOverscrollPrevention: ?() => void;

  componentDidMount() {
    const { getScrollLimitElement } = this.props;

    const scrollLimitElement = getScrollLimitElement && getScrollLimitElement();
    if (scrollLimitElement) {
      this.disableOverscrollPrevention = preventOverscroll(scrollLimitElement);
    }

    $('body').addClass('l-prevent-scroll');
  }

  componentWillUnmount() {
    if (this.disableOverscrollPrevention) {
      this.disableOverscrollPrevention();
    }
    $('body').removeClass('l-prevent-scroll');
  }

  render(): React.Node {
    return <div>{this.props.children}</div>;
  }
}
