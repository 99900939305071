// @flow

import * as React from 'react';
import styles from './InputErrorWrapper.scss';

type InputErrorWrapperProps = {|
  message: string,
  children: React.Node,
  errorId: string
|};
export default function InputErrorWrapper({
  message,
  children,
  errorId
}: InputErrorWrapperProps): React.Node {
  return (
    <div className={message ? styles.error : ''} data-error={errorId}>
      {children}
      {message && (
        <div className={styles.errorContainer}>
          <span>{message}</span>
        </div>
      )}
    </div>
  );
}
