// @flow

import * as React from 'react';

import type { Csrf } from 'common/types';

export type Props = Csrf;

export default class CSRFInputField extends React.Component<Props> {
  render(): React.Node {
    const { token, param } = this.props;
    return <input type="hidden" name={param} value={token} />;
  }
}
