// @flow

import * as React from 'react';
import SpinnerIcon from 'common/components/SpinnerIcon';

type Props = {| text?: ?string |};

class Spinner extends React.Component<Props> {
  render(): React.Node {
    return (
      <div className="m-loader">
        <div className="m-loader-container">
          <div className="m-loader-spinner">
            <SpinnerIcon />
          </div>
          {this.props.text && <p>{this.props.text}</p>}
        </div>
      </div>
    );
  }
}

export default Spinner;
