// @flow

import * as React from 'react';
import pleaseWaitFor from 'please-wait';
import SpinnerIcon from 'common/components/SpinnerIcon';
import type { RichTextInputProps } from './RichTextInput';
import styles from './RichTextInput.lazy.scss';

export default class LazyRichTextInput extends React.Component<
  RichTextInputProps,
  { RichTextInput: ?React.ComponentType<any>, ... }
> {
  state: { RichTextInput: ?React.ComponentType<any>, ... } = {
    RichTextInput: null
  };

  componentDidMount() {
    // flowlint-next-line unused-promise:off
    pleaseWaitFor('LAZY_LOADING_JS', () =>
      import('./RichTextInput').then(mod => {
        this.setState({
          RichTextInput: mod.default
        });
      })
    );
  }

  render(): React.Node {
    const { RichTextInput } = this.state;
    if (RichTextInput) {
      return <RichTextInput {...this.props} />;
    } else {
      return (
        <div className={styles.loading}>
          <SpinnerIcon />
        </div>
      );
    }
  }
}

export {
  Toolbar,
  ToolbarFormatGroup,
  ToolbarButton,
  ToolbarButtonLink,
  ToolbarSeparator
} from './Toolbar';
