// @flow

import currentSite from 'site/currentSite';

export default function prependCountryAndLocale(path: string): string {
  const siteCountryInPath = currentSite().countryInPath;
  const locale = __LOCALE__;

  if (siteCountryInPath === null) {
    return path;
  } else {
    return ['/', siteCountryInPath, '/', locale, path].join('');
  }
}
