// @flow

import { retrieve } from 'putki';

export function isFeatureEnabled(name: string): boolean {
  const enabledFeatures = retrieve('enabled_features');
  if (Array.isArray(enabledFeatures)) {
    return enabledFeatures.includes(name);
  }
  return false;
}
