/* This is copypasta, disable offending lint rules */
/* eslint-disable consistent-return, no-param-reassign, no-eq-null, eqeqeq, prefer-template */

import moment from 'moment';

const ANONYMOUS = '<<anonymous>>';

// Stolen from ReactPropTypes

function createChainableTypeChecker(validate) {
  function checkType(isRequired, props, propName, componentName, location) {
    componentName = componentName || ANONYMOUS;
    if (props[propName] == null) {
      if (isRequired) {
        return new Error(
          'Required prop `' +
            propName +
            '` was not specified in `' +
            componentName +
            '`.'
        );
      }
    } else {
      return validate(props, propName, componentName, location);
    }
  }

  const chainedCheckType = checkType.bind(null, false);
  chainedCheckType.isRequired = checkType.bind(null, true);

  return chainedCheckType;
}

function createDateOfFormatChecker(format) {
  function validate(props, propName, componentName) {
    const propValue = props[propName];
    const mdate = moment.utc(propValue, format, true);
    if (!mdate.isValid()) {
      return new Error(
        'Invalid prop `' +
          propName +
          '` of value `' +
          propValue +
          '` supplied to ' +
          '`' +
          componentName +
          '`, expected a valid date in format `' +
          format +
          '`.'
      );
    }
  }
  return createChainableTypeChecker(validate);
}

export const dateOfFormat = createDateOfFormatChecker;
export const iso8601 = createDateOfFormatChecker(moment.ISO_8601);
