// @flow

import * as React from 'react';

import keys from 'lodash/keys';
import { eventTargetValue } from 'common/eventTargets';

type Props<
  Value: string,
  Label: string,
  Options: { [value: Value]: Label, ... }
> = {
  value: Value,
  options: Options,
  onValue: Value => void,
  ...
};

export default function DropdownSelector<
  Value: string,
  Label: string,
  Options: { [value: Value]: Label, ... }
>(props: Props<Value, Label, Options>): React.Node {
  const { value, options, onValue, ...otherProps } = props;

  const onChange = (e: Event) => {
    const nextValue = keyPresence(options, eventTargetValue(e));
    if (nextValue !== null) {
      onValue(nextValue);
    }
  };
  let selectedValue: null | Value | string = keyPresence(options, value);
  if (selectedValue === null) {
    selectedValue = '';
  }

  return (
    <select {...otherProps} value={selectedValue} onChange={onChange}>
      {keys(props.options).map(optionValue => (
        <option value={optionValue} key={optionValue}>
          {props.options[optionValue]}
        </option>
      ))}
    </select>
  );
}

function keyPresence<Key: string, Value>(
  map: { [key: Key]: Value, ... },
  maybeKey: string
): Key | null {
  // We lack two kinds of type evidence here:
  // - that we should be able to check for presence of a possibly-undefined key on a map
  // - and that if that key is definitely in the map then it belongs to the type Key
  // $FlowFixMe[incompatible-type]
  if (typeof map[maybeKey] !== 'undefined') {
    return (maybeKey: $FlowFixMe<Key>);
  }
  return null;
}
