// @flow

import type { JSONValue } from 'common/types';

import { object, record, nullable, string, boolean } from 'common/json/extract';

const DATA_KEY = 'visitor_data';

export type Visitor = {|
  eventAt: string | null,
  otherDatesPossible: boolean | null,
  name: string | null,
  email: string | null,
  phone: string | null,
  company: string | null,
  eventType: string | null,
  inquiryBody: string | null
|};

export function saveVisitorDetails(visitor: Partial<Visitor>): void {
  const currentData = getVisitorDetails();
  try {
    window.localStorage.setItem(
      DATA_KEY,
      JSON.stringify({ ...currentData, ...visitor })
    );
  } catch (e) {
    // We end up here when the user local storage quota is full or user private browses on mobile Safari.
  }
}

export function deleteVisitorDetails(): void {
  try {
    localStorage.removeItem(DATA_KEY);
  } catch (e) {
    // We don't care of potential errors
  }
}

const extractVisitor: JSONValue => Visitor = object(
  record({
    eventAt: nullable(string),
    otherDatesPossible: nullable(boolean),
    name: nullable(string),
    email: nullable(string),
    phone: nullable(string),
    company: nullable(string),
    eventType: nullable(string),
    inquiryBody: nullable(string)
  })
);

export function getVisitorDetails(): Visitor {
  let parsedData = {};
  try {
    const data = window.localStorage.getItem(DATA_KEY);
    if (data) {
      parsedData = JSON.parse(data);
    }
  } catch (_err) {
    // We don't care if localStorage.getItem or JSON.parse throws
  }
  return extractVisitor(parsedData);
}
