// @flow

/**
 * Flag a branch of an if-else condition or a switch case that should be impossible to reach.
 * Flowtype will complain if it thinks the branch can be reached.
 *
 * Example:
 *
 *   const thisMatchIsUnexhaustive = (x: string | number) => {
 *     if (typeof x === 'string') {
 *       console.log('string');
 *     } else {
 *       impossible(x);
 *     }
 *   };
 */
export default function impossible(value: empty): empty {
  throw new Error(`Unexpected case '${value}'`);
}
