// @flow

import * as React from 'react';
import classnames from 'classnames';

// NOTE: DEPRECATED
// This is for FontAwesome version 4.
// Prefer FontAwesomeIcon, which is version 5.
export default class FAIcon extends React.Component<{
  name: string,
  fixedWidth?: boolean,
  spin?: boolean,
  pulse?: boolean,
  className?: string,
  ...
}> {
  render(): React.Node {
    const { name, fixedWidth, className, spin, pulse, ...props } = this.props;

    const classes = classnames(
      'fa',
      `fa-${name}`,
      {
        'fa-fw': fixedWidth,
        'fa-spin': spin,
        'fa-pulse': pulse
      },
      className
    );

    return <i {...props} className={classes} />;
  }
}

export type StackProps = {|
  foregroundIcon: string,
  backgroundIcon: string
|};

export function IconStack(props: StackProps): React.Node {
  return (
    <span className="fa fa-stack">
      <FAIcon name={props.backgroundIcon} className="fa-stack-2x" />
      <FAIcon name={props.foregroundIcon} className="fa-stack-1x" />
    </span>
  );
}
